.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 300px;
  }
  
  .modal h2 {
    margin-top: 0;
  }
  
  .modal button {
    padding: 10px 20px;
    background-color: #007A33;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .modal button:hover {
    background-color: #00522C;
  }
  