.jerseys-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -50px; /* Adjust for spacing below the scoreboard */
    overflow: hidden;
  }
  
  .jersey {
    width: 200px; /* Adjust based on jersey size */
    margin: 0 0px;
    transform: translateY(100%); /* Start off-screen (bottom) */
    opacity: 0;
    transition: transform 1s ease-in, opacity 1s ease-in;
  }
  
  .animate-in {
    transform: translateY(0); /* Slide into view */
    opacity: 1; /* Fade in */
  }
  @media only screen and (max-width: 375px) {
    .jerseys-container {
      max-width: 00%; /* Minimal padding for smaller screens */
    }
  }

  @media only screen and (max-width: 344px) {
    .jerseys-container {
      max-width: 100%; /* Full width */
      height: 50%; /* Allow height to adjust based on aspect ratio */
    }
  
    .jersey {
      width: 150px; /* Resize jersey to fit better on smaller screens */
      height: auto; /* Maintain aspect ratio */
      padding-top: 0px;
    }
  }

  @media only screen and (max-width: 375px) {
    .jerseys-container {
      max-width: 100%; /* Full width */
      height: auto; /* Allow height to adjust based on aspect ratio */
    }
  
    .jersey {
      width: 150px; /* Resize jersey to fit better on smaller screens */
      height: auto; /* Maintain aspect ratio */
      padding-top: 0px;
    }
  }

  @media only screen and (width: 540px) {
    .jerseys-container {
      max-width: 00%; 
      height: auto; /* Allow height to adjust based on aspect ratio */
    }
  
    .jersey {
      width: 150px; /* Resize jersey to fit better on smaller screens */
      height: auto; /* Maintain aspect ratio */
      padding-top: 0px;
    }
  }

  @media (max-width: 430px) {
    h1 {
      font-size: 1.5rem; /* Reduce font size */
      margin-bottom: 10px; /* Add space below title */
    }
  }

  
