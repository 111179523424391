.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 600px; /* Set a maximum width */
  width: 90%; /* Adjust width to fit smaller screens */
  max-height: 80vh; /* Ensure modal fits within the screen */
  overflow-y: auto; /* Allow scrolling if content overflows */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Adjust font sizes and layout for small screens */
@media (max-width: 600px) {
  .modal-content {
    padding: 15px;
    max-height: 90vh; /* Slightly larger for very small screens */
  }

  h2 {
    font-size: 18px; /* Scale down heading */
  }

  h3 {
    font-size: 16px; /* Scale down subheading */
  }

  .stats-header {
    flex-direction: column; /* Stack the stats vertically */
    margin-bottom: 15px;
  }

  .header-item p:first-of-type {
    font-size: 14px;
  }

  .header-item p:last-of-type {
    font-size: 16px;
  }
}

/* Styles for the stats header section */
.stats-header {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.header-item {
  text-align: center;
}

.header-item p:first-of-type {
  font-size: 16px;
  font-weight: bold;
}

.header-item p:last-of-type {
  font-size: 18px;
  margin-top: 5px;
}

/* Button styles */
.modal-close-button {
  background-color: #007A33;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  width: 100%; /* Make the button span full width */
  max-width: 300px;
  font-size: 16px;
}

.modal-close-button:hover {
  background-color: #00522C;
}
