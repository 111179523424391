.toast-notification {
    position: fixed;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the notification */
    background-color: #007A33;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 1000; /* Ensure it's on top of other elements */
    animation: fadeInOut 3s forwards; /* Animation */
  }
  
  @keyframes fadeInOut {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) translateY(20px); /* Initial position */
    }
    50% {
      opacity: 1;
      transform: translate(-50%, -50%); /* Center position */
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -50%) translateY(20px); /* Exit position */
    }
  }
  