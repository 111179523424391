.input-container {
    position: relative;
    margin-bottom: 20px;
  }

    /*Surface Duo Modification*/
    @media (width: 375px) {
      .input-container {
        position: relative;
        margin-bottom: -10px;
      }
    }

      /*title*/
      @media (width: 375px) {
        .input-container {
          position: relative;
          margin-bottom: -10px;
        }
      }

  /*Surface Duo Modification*/
  @media (width: 540px) {
    .input-container {
      position: relative;
      margin-bottom: -10px;
    }
  }
  
  .player-input {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    box-sizing: border-box;
  }
  

  .suggestions {
    list-style-type: none;
    padding: 0;
    margin: 0; /* Remove margins */
    width: 100%; /* Ensure suggestions take full width */
    max-width: 300px;
    border: 1px solid #ddd;
    border-radius: 5px;
    max-height: 160px;
    overflow-y: auto;
    background-color: white; /* White background for suggestions */
    box-sizing: border-box;
    z-index: 10; /* Ensure it appears above other content */
    position: absolute; /* Position suggestions absolutely */
    top: 100%; /* Place suggestions directly below the input */
    left: 0; /* Align suggestions with the input field */
  }
  
  .suggestion-item {
    padding: 10px;
    cursor: pointer;
    background-color: white;
    border-bottom: 1px solid #ddd;
    text-align: center;
  }
  
  .suggestion-item:hover {
    background-color: #f0f0f0;
  }
  
/* Responsive suggestions list for small screens */
/* For screens less than 768px */

@media only screen and (max-width: 767px) {
  .suggestions {
    width: 90%; 
    max-width: none; 
    margin: 0 auto; 
    position: absolute; 
    z-index: 1000; 
    border: 1px solid #ddd; 
    border-radius: 5px; 
    background-color: white; 
  }

  .suggestions li {
    padding: 10px; 
    font-size: 14px; 
  }
}

