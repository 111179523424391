/* Hide the title when screen width is less than 768 pixels */
/*
@media (max-width: 375px) {
    .hidden-title {
      display: none; 
    }
  }
  */

  @media (max-width: 375px) {
    .hidden-title {
      display: block;
      font-size: 20px;
      white-space: normal;
      overflow: visible;
      text-align: center;
      margin: 10px auto; /* Adjust margin */
      padding: 100px 0; /* Adjust padding if necessary */
      height: auto;
    }
  }

  @media (max-width: 540px) {
    .hidden-title {
      display: block;
      font-size: 20px;
      white-space: normal;
      overflow: visible;
      text-align: center;
      margin: 10px auto; /* Adjust margin */
      padding: 40px 0; /* Adjust padding if necessary */
      height: auto;
    }
  }

  .jersey-animation {
    margin-top: 20px;
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
  
  
/* Font size for guess row cells on smaller screens */
@media only screen and (max-width: 767px) {
    .guess-item {
      font-size: 0px; /* Adjust the font size for smaller screens */
    }
  
    .guess-header div {
      font-size: 14px; /* Adjust the font size for headers on smaller screens */
    }
  }

/* Game.css */

/* For small screens (less than 768px) */
@media only screen and (max-width: 767px) {
    .flip-back {
      font-size: 16px; /* Smaller font size for small screens */
    }
  }
  
  /* For larger screens */
  @media only screen and (min-width: 767px) {
    .flip-back {
      font-size: 18px; /* Default font size for larger screens */
    }
  }
  
  .input-container {
    position: relative; /* To position suggestions absolutely */
    margin-bottom: 20px; /* Spacing between input and guesses */
  }

  /* Responsive suggestions list for small screens */
  @media only screen and (max-width: 767px) {
    .suggestions {
      width: 90%; /* Adjust width for small screens */
    }
  }
  
  .suggestions li {
    padding: 10px;
    cursor: pointer;
    background-color: white;
    border-bottom: 1px solid #ddd;
    text-align: center;
  }
  
  .suggestions li:hover {
    background-color: #f0f0f0;
  }
  
  .copy-results-button {
    width: 100%; /* 100% width of the grid */
    max-width: 700px; /* Max width */
    padding: 10px;
    background-color: #007A33; /* Button color */
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 20px; /* Space above the button */
    font-size: 1.2rem; /* Adjust font size */
  }
  
  .copy-results-button:hover {
    background-color: #00522C; /* Darken on hover */
  }
  
  .copy-results-button {
    background-color: #007A33; /* Change to your desired button color */
    color: white; /* Change to your desired text color */
    border: 2px solid black; /* Black border */
    border-radius: 5px; /* Rounded corners (optional) */
    padding: 10px 20px; /* Padding for the button */
    font-size: 1rem; /* Font size */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s, transform 0.3s; /* Smooth transitions for hover effects */
  }
  
  .copy-results-button:hover {
    background-color: #005f24; /* Darker shade on hover */
    transform: scale(1.05); /* Slightly enlarge the button on hover */
  }
  
  .app {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    min-height: 100vh; /* Full screen height */
    width: 100vw;      /* Full screen width */
    box-sizing: border-box;
    overflow: hidden;  /* Prevent content overflow */
  }
  
  /* Container for both buttons */
.button-container {
  display: flex;
  justify-content: center;
  gap: 20px; /* Space between buttons */
  margin-top: 20px; /* Add some space at the top */
}

/* Stats and Support button styles */
.stats-button,
.support-button {
  background-color: #4CAF50; /* Green background */
  font-family: 'NBACeltics';
  src: url('./fonts/NBACeltics.ttf') format('truetype');
  color: white;
  font-size: 18px; /* Increase font size */
  padding: 12px 24px; /* Add padding for a larger button */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  text-decoration: none; /* Remove underline from hyperlink */
  display: inline-block;
  width: 150px; /* Ensure both buttons are the same width */
  transition: background-color 0.3s ease; /* Smooth hover transition */
}

/* Change background color on hover */
.stats-button:hover,
.support-button:hover {
  background-color: #45a049;
}

/* Responsive styles */
@media (min-width: 768px) {
  .stats-button,
  .support-button {
    width: 180px; /* Larger buttons for bigger screens */
    font-size: 20px;
    padding: 14px 28px;
  }
}

@media (max-width: 767px) {
  .stats-button,
  .support-button {
    width: 100%; /* Larger buttons for bigger screens */
    font-size: 20px;
    padding: 14px 28px;
  }
}
