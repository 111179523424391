.copy-results-bar {
    display: flex;
    flex-direction: column; /* Arrange elements vertically */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    background-color: #007A33;
    color: white;
    padding: 20px;
    border-radius: 5px;
    margin: 20px 0;
    width: 100%;
    max-width: 700px;
    box-sizing: border-box;
  }
  
  .copy-results-bar p {
    margin: 0;
    font-size: 1.2rem;
    margin-bottom: 15px; /* Add space below the text */
  }
  
  .copy-button {
    background-color: white;
    color: #007A33;
    border: 2px solid #007A33;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s, transform 0.3s;
    text-align: center;
  }
  
  .copy-button:hover {
    background-color: #00522C;
    color: white;
    transform: scale(1.05);
  }
  