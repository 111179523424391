/* Import the NBA Celtics font */
@font-face {
  font-family: 'NBACeltics';
  src: url('./fonts/NBACeltics.ttf') format('truetype');
}

/* Apply the font globally */
body, .app {
  font-family: 'NBACeltics', sans-serif;
  margin: 0;
  padding: 0;
  min-height: 100vh; /* Full viewport height */
  background-color: #007A33; /* Celtics green background */
}

/* Ensure the background stays green at all times */
html, body {
  height: 100%;
  background-color: #007A33; /* Celtics green */
}

/* Container for the entire app */
/*
.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}*/


/* Ensure the grid container is centered and stays centered */
.grid-container {
  background-color: rgba(255, 255, 255, 0.8); /* Light background with slight transparency */
  border-radius: 15px;
  padding: 20px;
  margin: 20px;
  width: 100%;
  max-width: 90%; /* Set the max-width to 90% of the viewport */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Expand grid container when the screen is wider than 1240px */
@media only screen and (min-width: 1240px) {
  .grid-container {
    max-width: 1400px; /* Expand the max-width beyond 1240px */
  }
}

/* Input styling */
input {
  padding: 10px;
  margin: 10px;
  width: 100%;
  max-width: 300px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  box-sizing: border-box;
}

/* Responsive input for small screens */
@media only screen and (max-width: 767px) {
  input {
    width: 90%; /* Adjust input size for smaller screens */
    font-size: 14px; /* Smaller font for small devices */
  }
}

button {
  padding: 10px 20px;
  background-color: #007A33;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #00522C;
}

.suggestions li {
  padding: 10px;
  cursor: pointer;
  background-color: white;
  border-bottom: 1px solid #ddd;
  text-align: center;
}

.suggestions li:hover {
  background-color: #f0f0f0;
}

/* Define consistent column widths for the header and rows */
.guess-header,
.guess-row {
  display: grid;
  grid-template-columns:  2fr 1.5fr 1.5fr 1.5fr 2fr 1.5fr; /* Adjust the column width as needed */
  align-items: center;
  justify-items: center;
  font-weight: bold;
  border-collapse: collapse;
  height: 60px;
}

.guess-header {
  background-color: #007A33;
  color: white;
  border-bottom: 2px solid black;
  height: 60px;
}

.guess-header div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
/* Flip card container */
.flip {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.8s ease-in-out;
}

/* Flip active */
.flip.flip-active {
  transform: rotateX(180deg);
}

/* Flip front (grey side) */
.flip-front,
.flip-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: black;
}

/* Front side (initial grey side) */
.flip-front {
  background-color: #cccccc;
}


/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 300px;
}

.modal h2 {
  margin-top: 0;
}

.modal button {
  padding: 10px 20px;
  background-color: #007A33;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

.modal button:hover {
  background-color: #00522C;
}

/* Fade-in effect for the game */
.fade-in {
  opacity: 0;
  animation: fadeIn 2s forwards;
  
}

@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}

/* Mobile-first responsive design */

/* For small devices like iPhones (max-width: 767px) */
@media only screen and (max-width: 767px) {
  .app {
    padding: 30px;
  }

  h1 {
    font-size: 1.2rem; /* Smaller font for mobile devices */
  }

  .grid-container {
    width: 100%;
    padding: 10px;
    box-shadow: none; /* Reduce shadow effects on smaller screens */
  }

  .guess-header,
  .guess-row {
    font-size: 12px; /* Smaller font size for mobile devices */
  }

  .modal {
    width: 90%;
  }

  input {
    font-size: 1rem; /* Smaller input text */
    max-width: 100%; /* Full width for smaller devices */
  }

  button {
    font-size: 1rem; /* Smaller button text */
  }
}

/* For laptops and larger devices (min-width: 1025px) */
@media only screen and (min-width: 1025px) {
  .app {
    padding: 20px;
  }

  h1 {
    font-size: 2.5rem;
  }

  .grid-container {
    padding: 20px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 1400px; /* Expand the max-width for larger screens */
  }

  .modal {
    width: 50%;
  }

  input {
    max-width: 500px; /* Center input and restrict width for laptops */
    margin: 0 auto; /* Center the input */
  }
}

.app h1 {
  font-size: 5rem; /* Change this to the desired size */
  color: white; /* Ensure the color matches your design */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Keep the shadow for readability */
  overflow: hidden;
}

.app p {
  font-size: 2rem;
}
