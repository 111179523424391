.correct {
  background-color: #6AAA64; /* Green for correct */
  color: white;
}

.guess-header,
.guess-row {
  display: grid;
  grid-template-columns:  2fr 1.5fr 1.5fr 1.5fr 2fr 1.5fr; /* Align columns for both header and rows */
  grid-gap: 10px; /* Add some space between columns */
  padding: 6px;
  align-items: center;
  
}

.guess-header {
  font-weight: bold;
  background-color: #6AAA64; /* Light background color for header */
  border-bottom: 2px solid #ccc;
  display: grid;
  width: 98%;
}


.incorrect {
  display: grid;
  justify-items: center;
  align-items: center;
  background-color: #aa9a9a; /* Red for incorrect guesses */
  color: white;
}

.skeleton {
  /*background-color: #e0e0e0; /* Light grey background for skeleton */
}

.flip-back.yellow {
  background-color: #f0e080 !important;
  color: rgb(100, 92, 92);
  border: 1px solid #9f9834; 

}

correct {
  background-color: #545d54;
  color: white;
  border: 1px solid #0b721c; 
}

.incorrect {
  background-color: #aea3a3;
  color: white;
  border: 1px solid #918b8b; 
}

.winning-card {
  background-color: white;
  border: 2px solid #268b50; /* Celtics green trim */
  color: #007A33; /* Celtics green text */
  font-weight: bold;
  transition: transform 0.6s ease-in-out;
}

.guess-grid {
  display: grid;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
}

.guess-item {
  background-color: #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 18px;
}
.flip-back {
  transform: rotateX(180deg);
  position: relative;
  border-width: 1px;  /* Default border width */
  border-style: solid;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  box-sizing: border-box;
}

@media only screen and (max-width: 767px) {
  .guess-item {
    font-size: 12px; /* Adjust font size for smaller screens */
  }

  .guess-header {
    font-size: 14px; /* Optional: Adjust header font size for smaller screens */
  }
}

@media only screen and (max-width: 767px) {
  .grid-container {
    min-width: 100%; 
  }

  .guess-header,
  .guess-row {
    font-size: 12px;
    padding: 0px;
    width: 100%;
  }

  .guess-item {
    font-size: 14px; 
  }
}